import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gy-5 g-xl-8" }
const _hoisted_2 = { class: "col-xxl-4" }
const _hoisted_3 = { class: "col-xxl-8" }
const _hoisted_4 = { class: "row gy-5 g-xl-8" }
const _hoisted_5 = { class: "col-xxl-6" }
const _hoisted_6 = { class: "col-xxl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTReport = _resolveComponent("KTReport")!
  const _component_KTReportHorizontal = _resolveComponent("KTReportHorizontal")!
  const _component_KTClass = _resolveComponent("KTClass")!
  const _component_KTCourse = _resolveComponent("KTCourse")!
  const _component_KTExam = _resolveComponent("KTExam")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        false
          ? (_openBlock(), _createBlock(_component_KTReport, {
              key: 0,
              "widget-classes": "card-xl-stretch mb-xl-8",
              "chart-color": "primary",
              "chart-height": "150"
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_KTReportHorizontal, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "chart-color": "primary",
          "chart-height": "150"
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_KTClass, { "widget-classes": "card-xxl-stretch mb-5 mb-xl-8" })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_KTCourse, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_KTExam, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
      ])
    ])
  ], 64))
}