
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import KTReport from "@/views/dashboard/Report.vue";
import KTReportHorizontal from "@/views/dashboard/ReportHorizontal.vue";
import KTClass from "@/views/dashboard/Class.vue";
import KTCourse from "@/views/dashboard/Course.vue";
import KTExam from "@/views/dashboard/Exam.vue";

export default defineComponent({
  name: "dashboard",
  components: {
    KTReport,
    KTReportHorizontal,
    KTClass,
    KTCourse,
    KTExam,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("首页");
    });
  }
});
