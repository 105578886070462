
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast.js";
import { AxiosRequestConfig } from "node_modules/axios";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "kt-widget-9",
  props: {
    widgetClasses: String
  },
  data() {
    return {
      //加载
      loading: false,
      //分页
      pagination: {
        currentPage: 1,
        perPage: 5,
        totalRows: -1
      },
      //搜索
      searchKey: "",
      //排序
      sortField: "create_time",
      sortOrder: "descending",
      //数据
      tableData: []
    };
  },
  methods: {
    handleDetail(item) {
      const params = {
        row: JSON.stringify(item),
        path: this.$route.path
      };
      store.dispatch(Actions.SET_SECONDARY_PARAMS_ACTION, params);
      this.$router.push({
        path: "/course/detail"
      });
    },
    loadData() {
      const data = {
        prefix: {
          currentPage: this.pagination.currentPage,
          perPage: this.pagination.perPage,
          searchKey: this.searchKey,
          sortField: this.sortField,
          sortOrder: this.sortOrder
        },
        type: "00"
      };
      this.loading = true;
      ApiService.post("MemberCourse/Course", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.tableData = data.data.items;
            this.pagination.totalRows = data.data.meta.totalRows;
            store.dispatch(Actions.SET_REPORT_COURSE, data.data.meta.totalRows); //设置course_num
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    if (store.getters.isUserAuthenticated) {
      this.loadData();
    }
  }
});
